import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CronOptions } from "ngx-cron-editor";
import cronstrue from "cronstrue";
import { FileType, IJobsData, JobType, ManageBatchJobsService } from "../manage-batch-jobs.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-edit-batch-job",
  templateUrl: "./edit-batch-job.component.html",
  styleUrls: ["./edit-batch-job.component.scss"],
})
export class EditBatchJobComponent implements OnInit {
  editJobForm: UntypedFormGroup;
  jobTypes = JobType;
  pmlFileName: string;
  pmlCredentialsFileName: string;
  credFileResponseGuid: string;
  credFileUploadSuccess = false;
  pmlFileResponseGuid: string;
  pmlFileUploadSuccess = false;
  public jobData: IJobsData;
  public loaded = false;
  public pmlError: string;
  public credError: string;
  public error: string;
  private id: string;
  saveError: string;
  checkBoxEvent: any = null; //eslint-disable-line  @typescript-eslint/no-explicit-any
  scheduleDescription: string;
  newScheduleDescription: string;
  isModifySchedule = false;
  finalSchedule: string;

  set newSchedule(val) {
    if (val === "0/1 * 1/1 * *" || val === "0 0/1 1/1 * *" || val === null || val === undefined) {
      this.newScheduleDescription = "Select the desired schedule";
      this.editJobForm.controls["enableSchedule"].disable();
    } else {
      try {
        this.finalSchedule = val;
        this.newScheduleDescription = `${cronstrue.toString(val)} (GMT+00:00)`;
        this.editJobForm.controls["enableSchedule"].enable();
      } catch (error) {
        this.newScheduleDescription = "Invalid Schedule";
        this.editJobForm.controls["enableSchedule"].disable();
      }
    }
  }

  public cronOptions: CronOptions = {
    defaultTime: "00:00:00",

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: true,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: true,
    hideSpecificMonthWeekTab: true,

    use24HourTime: true,
    hideSeconds: true,

    cronFlavor: "standard", // standard or quartz
  };

  isMacFile = true;
  isPmlMacFile = true;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private manageBatchJobService: ManageBatchJobsService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.breadcrumbService.set(
      "@EditBatchJob",
      this.translate.instant(`batch_jobs.breadcrumbs.edit_page`)
    );
    this.manageBatchJobService.getJobDetailsById(this.id).subscribe(
      (data) => {
        this.jobData = data;
        this.editJobForm = this.fb.group({
          title: [this.jobData.title, [Validators.required]],
          description: [this.jobData.description],
          projectName: [this.jobData.projectName, [Validators.required]],
          schedule: [this.jobData.schedule, [Validators.required]],
          enableSchedule: [this.jobData.enableSchedule],
          jobType: [this.jobData.jobType],
          pmlMacro: [this.jobData.pmlFileName],
          pmlCredential: [this.jobData.pmlCredentialsFileName],
        });
        this.loaded = true;
        this.finalSchedule = this.jobData.schedule;

        this.scheduleDescription = `${cronstrue.toString(this.jobData.schedule)} (GMT+00:00)`;
      },
      (error: Error) => {
        this.error = error.message;
      }
    );
  }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    return !this.editJobForm.dirty;
  }

  onPmlFileSelected(file: File) {
    const pmlFile: File = file;
    if (!pmlFile) {
      return;
    }

    this.isPmlMacFile = pmlFile.name.split(".").pop().toLowerCase() === "pmlmac";
    if (!this.isPmlMacFile) {
      return;
    }

    this.pmlFileName = pmlFile.name;
    this.editJobForm.patchValue({
      pmlMacro: pmlFile,
    });

    const fd = new FormData();
    fd.append("file", pmlFile);

    this.manageBatchJobService.batchJobFileUploadPut(fd, FileType.Macro, this.id).subscribe(
      (data) => {
        this.pmlFileResponseGuid = data.toString();
        if (this.pmlFileResponseGuid) {
          this.pmlFileUploadSuccess = true;
        }
      },
      (error: Error) => {
        this.pmlError = error.message;
      }
    );
  }

  onCredentialFileSelected(file: File) {
    const credFile = file;
    if (!credFile) {
      return;
    }

    this.isMacFile = credFile.name.split(".").pop().toLowerCase() === "mac";
    if (!this.isMacFile) {
      return;
    }

    this.pmlCredentialsFileName = credFile.name;

    this.editJobForm.patchValue({
      pmlCredential: credFile,
    });

    const fd = new FormData();
    fd.append("file", credFile);

    this.manageBatchJobService.batchJobFileUploadPut(fd, FileType.Credential, this.id).subscribe(
      (data) => {
        this.credFileResponseGuid = data.toString();
        if (this.credFileResponseGuid) {
          this.credFileUploadSuccess = true;
        }
      },
      (error: Error) => {
        this.credError = error.message;
      }
    );
  }

  onSubmit(formData) {
    formData.pMLFileId = this.pmlFileUploadSuccess
      ? this.pmlFileResponseGuid
      : this.jobData.pmlFileId;
    formData.PMLCredentialsFileId = this.credFileUploadSuccess
      ? this.credFileResponseGuid
      : this.jobData.pmlCredentialsFileId;
    formData.schedule = this.finalSchedule ? this.finalSchedule : this.editJobForm.controls.value;

    if (!this.pmlFileName) {
      formData.pmlFileName = this.jobData.pmlFileName;
    } else {
      formData.pmlFileName = this.pmlFileName;
    }

    if (!this.pmlCredentialsFileName) {
      formData.pmlCredentialsFileName = this.jobData.pmlCredentialsFileName;
    } else {
      formData.pmlCredentialsFileName = this.pmlCredentialsFileName;
    }

    this.manageBatchJobService.editBatchJob(this.id, formData).subscribe(
      (data) => {
        this.router.navigate(["../.."], { relativeTo: this.route });
        return data;
      },
      (_error: Error) => {  //eslint-disable-line @typescript-eslint/no-unused-vars
        this.saveError = "Failed to edit Batch Job.";
      }
    );
  }

  onCancel() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }
}
