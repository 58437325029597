import { Inject, Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { CONFIGURATION, Configuration } from "src/configuration";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, map, shareReplay } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

export interface IJobsData {
  createdAt: string;
  description: string;
  enableSchedule: boolean;
  id: string;
  isArchived: boolean;
  jobType: string;
  pmlCredentialsFileName: string;
  pmlFileName: string;
  projectName: string;
  schedule: string;
  title: string;
  pmlFileId: string;
  pmlCredentialsFileId: string;
  jobStatus: string;
  jobExecutions: IJobExecutions[];
}

export interface IJobExecutions {
  executionTime: string;
  status: JobStatus;
  executionEndTime: string;
}

export interface IListExecutionHistory {
  logFileName: string;
  executionTime: string;
  jobExecutionStatus: string;
}

const apiErrorCodes = {
  badAuthToken: 1003,
  unableToResolveInfrastructureContext: 1013,
  unableToProceedFurtherDueToMaintenance: 1014,
  batchJobFileUploadError: 2003,
  fileToBeUploadedNotFound: 2004,
  batchJobsFeatureNotAvailable: 2007,
};

const apiErrorsMap = Object.fromEntries(Object.entries(apiErrorCodes).map((a) => a.reverse()));

export const JobType = [
  "E3D-DESIGN",
  "E3D-DRAW",
  "E3D-ISODRAFT",
  "Administration-ADMIN",
  "Engineering-TAGS",
  "Engineering-CONFIGURATION",
];

export enum FileType {
  Macro = "MACRO",
  Credential = "CRED",
}

export enum JobStatus {
  Succeeded = 0,
  Failed = 1,
}

@Injectable({
  providedIn: "root",
})
export class ManageBatchJobsService {
  private featureEnabled$: Observable<boolean> | null;
  public timeZone: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
    private translate: TranslateService
  ) {
    // extract local timezone from local current datetime object
    this.timeZone = new Date()
      .toString()
      .match(/([A-Z]+[\+-][0-9]+.*)/)[1]
      .split(" ")[0];

    // format from GMT+0530 to GMT+05:30
    this.timeZone = "".concat(this.timeZone.substring(0, 6), ":", this.timeZone.substring(6, 8));
  }

  get batchjobsBaseUrl(): Observable<string> {
    if (!this.configuration.apis.batchjobs) {
      return throwError(
        () => ({ error: apiErrorCodes.batchJobsFeatureNotAvailable } as HttpErrorResponse)
      );
    }
    return of(this.configuration.apis.batchjobs);
  }

  handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse> {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(() => `Error: ${error.error.message}`);
    } else {
      // Server-side errors
      if (error.status === 404) {
        return of(null);
      }
      const errorCode = parseInt(error.error, 10);
      const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
      return this.translate.get(`errors.api.${apiErrorTranslationKey}`).pipe(
        map((message: string) => {
          throw new Error(message);
        })
      );
    }
  }

  convertDateToLocal(executionTime: string) {
    const unformattedDate = new Date(executionTime);
    return unformattedDate.toLocaleDateString("en-US", {
      hour12: true,
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }

  // converts human readable schedule in UTC time to local time without losing AM/PM
  convertScheduleTimeToLocal(humanReadableScheduleInUtc: string) {
    // Receives humanReadableSchedule in format: "At hh:mm AM, ....."
    const extractedTimeString = humanReadableScheduleInUtc.split(",")[0].split(" "); //[At,hh:mm,AM] or [At,hh:mm,PM]
    const [schedHrsUtc, schedMinsUtc] = extractedTimeString[1].split(":");
    const schedAmPm = extractedTimeString[2];

    let currentDateUTCString = new Date().toUTCString().substring(0, 25); //currentDateUTCString : 'Wed, 25 Jan 2023 14:20:36'
    const hrIndex = currentDateUTCString.indexOf(":") - 2;

    // replace the UTC hours & mins with extracted hrs & mins i.e. new value of currentDateUTCString : 'Wed, 25 Jan 2023 schedHrsUtc:schedMinsUtc:36'
    currentDateUTCString =
      currentDateUTCString.substring(0, hrIndex) +
      `${schedHrsUtc}:${schedMinsUtc}` +
      currentDateUTCString.substring(hrIndex + 5);

    currentDateUTCString =
      schedAmPm === "AM"
        ? currentDateUTCString.concat(" AM UTC")
        : currentDateUTCString.concat(" PM UTC");

    // generate local time according to the UTC time constructed above for today
    const localTime = new Date(currentDateUTCString)
      .toLocaleTimeString("en-US", { hour12: true, hour: "numeric", minute: "numeric" })
      .toString();

    return `${localTime}`;
  }

  featureEnabled(): Observable<boolean> {
    if (this.featureEnabled$) {
      return this.featureEnabled$;
    }

    this.featureEnabled$ = this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/featurestatus`, { observe: "response" })),
      catchError((error) => {
        const errorCode = parseInt(error.error, 10);
        if (
          [
            apiErrorCodes.batchJobsFeatureNotAvailable,
            apiErrorCodes.unableToResolveInfrastructureContext,
            apiErrorCodes.unableToProceedFurtherDueToMaintenance,
          ].includes(errorCode)
        ) {
          return of(false);
        }
        return throwError(() => error);
      }),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => response.status === 200), //eslint-disable-line @typescript-eslint/no-explicit-any
      shareReplay()
    );
    return this.featureEnabled$;
  }

  getBatchJobsList(): Observable<Array<IJobsData>> {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<IJobsData[]>(`${url}/batch`)),
      catchError(this.handleError.bind(this))
    );
  }

  removeJobEntry(id: string) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.delete<IJobsData[]>(`${url}/batch/${id}`)),
      catchError(this.handleError.bind(this))
    );
  }

  getJobDetailsById(id: string): Observable<IJobsData> {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<IJobsData>(`${url}/batch/${id}`)),
      catchError(this.handleError.bind(this))
    );
  }

  batchJobFileUpload(fd: FormData, fileType: FileType) {
    const _headers = new HttpHeaders({
      enctype: "multipart/form-data",
      "X-FileType": fileType,
    });
    const options = { headers: _headers };
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.post(`${url}/batchfileupload`, fd, options)),
      catchError(this.handleError.bind(this))
    );
  }

  batchJobFileUploadPut(fd: FormData, fileType: FileType, id: string) {
    const _headers = new HttpHeaders({
      enctype: "multipart/form-data",
      "X-FileType": fileType,
    });
    const options = { headers: _headers };
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.put(`${url}/batchfileupload/${id}`, fd, options)),
      catchError(this.handleError.bind(this))
    );
  }

  editBatchJob(id: string, formData: FormData) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.put(`${url}/batch/${id}`, formData)),
      catchError(this.handleError.bind(this))
    );
  }

  createBatchJob(formData: FormData) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.post(`${url}/batch`, formData)),
      catchError(this.handleError.bind(this))
    );
  }

  getLogDetails(id: string, executionTime: string, logFileName: string) {
    const params = new HttpParams()
      .set("BatchId", id)
      .set("ExecutionTime", executionTime)
      .set("LogFileName", logFileName);
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get(`${url}/logs`, {
          params: params,
          responseType: "text" as "json",
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  getLogsByTimeRange(batchId: string, startTime?: string, endTime?: string) {
    let params;
    if (startTime && endTime) {
      params = new HttpParams()
        .set("BatchId", batchId)
        .set("StartTime", startTime)
        .set("EndTime", endTime);
    } else {
      params = new HttpParams().set("BatchId", batchId);
    }
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get(`${url}/logs/byTimeRange`, {
          params: params,
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  executeBatchJobNow(id: string) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/batch/executeNow/${id}`)),
      catchError(this.handleError.bind(this))
    );
  }
}
