<con-page-layout>
  <con-page-header #pageHeader header="{{ 'batch_jobs.manage_batch_jobs.page_title' | translate }}">
    <div class="div-display-flex">
      <ul class="breadcrumb" *ngIf="currentFolderPath.length > 0">
        <li>{{ "workspaces.current_folder_text" | translate }}</li>
        <li *ngFor="let path of currentFolderPath">
          <span>{{ path }}</span>
        </li>
      </ul>
      <!-- Create Button -->
      <div class="create-button" *ngIf="featureEnabled">
        <button mat-icon-button (click)="refresh()" matTooltip="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
        <button mat-raised-button routerLink="../createbatchjob/" color="primary">
          {{ "batch_jobs.manage_batch_jobs.create_button_title" | translate }}
        </button>
      </div>
    </div>
  </con-page-header>

  <ng-container *ngIf="error; then errorState; else okState"></ng-container>
  <ng-template #okState>
    <con-page-layout-content>
      <div class="filter-class">
        <mat-form-field class="filter">
          <input matInput (keyup)="applyFilter($any($event.target).value)"
            placeholder="{{ 'workspaces.users_table.filter_placeholder' | translate }}" />
        </mat-form-field>
      </div>
      <div class="flex-container center" *ngIf="!loaded">
        <mat-spinner class="mat-spinner-align-center" color="primary"></mat-spinner>
      </div>
      <div class="flex-container" *ngIf="loaded">
        <div class="manageBatchJobs-container">
          <div class="mat-elevation-z8">
            <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
              <ng-container matColumnDef="title">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by Job title">
                  {{ "batch_jobs.manage_batch_jobs.job_title" | translate }}</mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element; let row">
                  <a routerLink="../viewbatchjob/{{ row.id }}">{{ element.title }}</a>
                </mat-cell>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="jobType">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by jobType">{{
                  "batch_jobs.manage_batch_jobs.jobType" | translate
                  }}</mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element">{{ "batch_jobs.jobTypes." + element.jobType |
                  translate }} </mat-cell>
              </ng-container>

              <!-- ProjectName Column -->
              <ng-container matColumnDef="projectName">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by ProjectName">
                  {{ "batch_jobs.manage_batch_jobs.project_name" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element"> {{ element.projectName }} </mat-cell>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.manage_batch_jobs.status" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element"> {{ element.jobStatus }} </mat-cell>
              </ng-container>

              <!-- Schedule Column -->
              <ng-container matColumnDef="schedule">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.manage_batch_jobs.schedule" | translate }} ({{ timeZone }})
                </mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element" [matTooltip]="element.schedule">
                  {{ element.humanReadableSchedule }}
                </mat-cell>
              </ng-container>

              <!-- LastExecuted Column -->
              <ng-container matColumnDef="lastExecuted">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.manage_batch_jobs.last_executed" | translate }}
                  ({{ timeZone }})
                </mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element">{{ element.formattedDate }} </mat-cell>
              </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef>
                  {{ "batch_jobs.manage_batch_jobs.description_text" | translate }}
                </mat-header-cell>
                <mat-cell fxFlex="60px" *matCellDef="let element"> {{ element.description }} </mat-cell>
              </ng-container>

              <!-- Checkbox Column -->
              <ng-container matColumnDef="enabled">
                <mat-header-cell fxFlex="60px" *matHeaderCellDef class="icon-align">
                  {{ "batch_jobs.manage_batch_jobs.enabled_text" | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-align">
                  <mat-icon *ngIf="row.enableSchedule">check </mat-icon>
                </mat-cell>
              </ng-container>

              <!-- Delete Icon Column -->
              <ng-container matColumnDef="remove">
                <mat-header-cell *matHeaderCellDef class="icon-align"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-align" (click)="removeJob(row.id, row.title)">
                  <uilab-icon-button class="delete" matTooltip="Delete batch job">
                    <mat-icon slot="icon">delete</mat-icon>
                  </uilab-icon-button>
                </mat-cell>
              </ng-container>

              <!-- Edit Icon Column -->
              <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef class="icon-align"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-align">
                  <uilab-icon-button class="edit" matTooltip="Edit batch job" routerLink="../editbatchjob/{{ row.id }}">
                    <mat-icon slot="icon">edit</mat-icon>
                  </uilab-icon-button>
                </mat-cell>
              </ng-container>

              <!-- ExecuteNow Icon Column -->
              <ng-container matColumnDef="executeNow">
                <mat-header-cell *matHeaderCellDef class="icon-align"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-align" (click)="executeNow(row.id, row.title)">
                  <uilab-icon-button class="explicit" matTooltip="Execute Now">
                    <mat-icon slot="icon">explicit</mat-icon>
                  </uilab-icon-button>
                </mat-cell>
              </ng-container>

              <!-- View Log History Icon Column -->
              <ng-container matColumnDef="logHistory">
                <mat-header-cell *matHeaderCellDef class="icon-align"></mat-header-cell>
                <mat-cell *matCellDef="let row" class="icon-align">
                  <uilab-icon-button class="logHistory" matTooltip="Execution History"
                    routerLink="../listexecutionhistory/{{ row.id }}">
                    <mat-icon slot="icon">insert_drive_file</mat-icon>
                  </uilab-icon-button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageIndex]="0" (page)="onChangePage($event)" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </div>
        </div>
        <div *ngIf="saveError">
          <h4 class="h4-color-red">{{ saveError }}</h4>
        </div>
      </div>
    </con-page-layout-content>
  </ng-template>
  <ng-template #errorState>
    <con-page-layout-content>
      <div class="content-container">
        <div class="inner-container">
          <div class="warning-container greeting-container">
            <h4 class="error-page-title">
              <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
              {{ "batch_jobs.manage_batch_jobs.warning_title" | translate }}
            </h4>
            <p>{{ error }}</p>
            <p [innerHtml]="'batch_jobs.manage_batch_jobs.warning_sub_title_html' | translate"></p>
          </div>
        </div>
      </div>
    </con-page-layout-content>
  </ng-template>
</con-page-layout>