import { Injectable } from "@angular/core";
import {
    AuthenticatedUser,
    AuthorizationService,
    ConnectApiClientService,
    ContextType
} from "@aveva/connect-web-core";

@Injectable({
    providedIn: "root",
})
export class AuthRoleCheckService {
    constructor(
        private authService: AuthorizationService,
        private connectApiClient: ConnectApiClientService
    ) { }
    async isAccountAdmin(user: AuthenticatedUser): Promise<boolean> {
        const accountSpecificRoles = await this.connectApiClient.userManagement.getAccessDocument(user.id,
            {
                account: user.account,
                contextType: ContextType.account,
            });
        return accountSpecificRoles.roles.some(role => role.name === 'Administrator');
    }
    async isAdminSuperUser(user: AuthenticatedUser): Promise<boolean> {
        return this.authService.hasAnyRole([{
            name: "Users Admin Superuser",
            context: {
                contextType: ContextType.solution,
                solution: user.solution,
                solutionType: user.solutionDefinition,
            },
        },
        {
            name: "Admin",
            context: {
                contextType: ContextType.solution,
                solution: user.solution,
                solutionType: user.solutionDefinition,
            },
        }])
    }
}
