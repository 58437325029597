import { Component, OnDestroy, OnInit, SecurityContext, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IJobsData, ManageBatchJobsService } from "../manage-batch-jobs.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ConfirmationPopupService } from "src/app/shared/services/confirmation-popup.service";
import { TranslateService } from "@ngx-translate/core";
import cronstrue from "cronstrue";
import { BreadcrumbService } from "xng-breadcrumb";
import { DomSanitizer } from "@angular/platform-browser";
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-manage-batch-jobs",
  templateUrl: "./manage-batch-jobs.component.html",
  styleUrls: ["./manage-batch-jobs.component.scss"],
})
export class ManageBatchJobsComponent implements OnInit, OnDestroy {
  public error: string;
  displayName: string;
  currentFolderPath: string[] = [];
  startTime = "";
  endTime = "";
  public loaded = false;
  timeZone: string;
  public paginator: MatPaginator;
  public sort: MatSort;

  public jobTypesArr: string[];
  displayedColumns = [
    "title",
    "jobType",
    "projectName",
    "status",
    "schedule",
    "lastExecuted",
    "description",
    "enabled",
    "remove",
    "edit",
    "executeNow",
    "logHistory",
  ];
  dataSource = new MatTableDataSource<IJobsDataView>([]);
  saveError: string;
  public featureEnabled: boolean;
  private readonly refresh$ = new BehaviorSubject<void>(null);

  constructor(
    public route: ActivatedRoute,
    private manageBatchJobService: ManageBatchJobsService,
    private confirmationDialog: ConfirmationPopupService,
    private router: Router,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private sanitizer: DomSanitizer
  ) { }

  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
    this.restorePreviousSort();
  }
  @ViewChild("pageHeader") pageHeader: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  @ViewChild(MatPaginator) set MatPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
    this.restorePreviousPageSize();
  }

  onChangePage(pe: PageEvent) {
    const sanitizedValue: string = this.sanitizer.sanitize(
      SecurityContext.HTML,
      pe.pageSize.toString()
    );
    sessionStorage.setItem("previousPageSize", sanitizedValue);
  }

  onSortChange(se: MatSort) {
    const sanitizedPreviousSortColumnNameValue: string = this.sanitizer.sanitize(
      SecurityContext.HTML,
      se.active
    );
    const sanitizedPreviousSortDirectionValue: string = this.sanitizer.sanitize(
      SecurityContext.HTML,
      se.direction
    );
    const sanitizedValue: string = JSON.stringify({
      active: sanitizedPreviousSortColumnNameValue,
      direction: sanitizedPreviousSortDirectionValue,
    });
    sessionStorage.setItem("previousSort", sanitizedValue);
  }

  async ngOnInit() {
    this.breadcrumbService.set(
      "@ManageBatchJobs",
      this.translate.instant(`batch_jobs.breadcrumbs.manage_page`)
    );

    this.route.data.subscribe((data: { arrayOfPaths: string[] }) => {
      this.currentFolderPath = data.arrayOfPaths;
    });

    this.timeZone = this.manageBatchJobService.timeZone;

    this.manageBatchJobService.featureEnabled().subscribe(
      (featureEnabled) => (this.featureEnabled = featureEnabled),
      (error) => (this.error = error.message)
    );

    this.refresh$.pipe(
      switchMap(() => this.manageBatchJobService.getBatchJobsList()),
    ).subscribe(
      (jobsData) => {
        this.dataSource = new MatTableDataSource<IJobsDataView>(
          jobsData
            .filter((x) => !x.isArchived)
            .map((x) => {
              const view = { ...x } as IJobsDataView;
              if (view.jobExecutions) {
                const lastJobExecution = view.jobExecutions[view.jobExecutions.length - 1]
                view.formattedDate = this.manageBatchJobService.convertDateToLocal(
                  lastJobExecution.executionEndTime == null ? lastJobExecution.executionTime : lastJobExecution.executionEndTime
                );
              } else {
                view.formattedDate = null;
              }
              if (!view.hasOwnProperty('jobStatus')) {
                view.jobStatus = "";
              }
              else
                if (view.jobStatus === null) {
                  view.jobStatus = 'SCHEDULED'
                }
              try {
                view.humanReadableSchedule = cronstrue.toString(x.schedule);
                const cronLocalTime = this.manageBatchJobService.convertScheduleTimeToLocal(
                  view.humanReadableSchedule
                );

                const regex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;

                view.humanReadableSchedule = view.humanReadableSchedule.replace(
                  regex,
                  cronLocalTime
                );
              } catch (e) {
                view.humanReadableSchedule = "Invalid Cron expression";
              }
              return view;
            })
        );


        this.dataSource.filterPredicate = (data: IJobsDataView, filterValue: string): boolean =>
          Object.keys(data).some((key: string) => {
            if (
              key === "title" ||
              key === "jobType" ||
              key === "projectName" ||
              key === "schedule" ||
              key === "description" ||
              key === "formattedDate" ||
              key === "jobStatus"
            ) {
              const value = data[key];
              // only validating string type
              if (typeof value === "string") {
                // only for job type, verify with values in en.json
                if (key === "jobType") {
                  const translationKey = `batch_jobs.jobTypes.${data.jobType}`;
                  const jobType = this.translate.instant(translationKey).toLowerCase();
                  return jobType.includes(filterValue);
                }
                return value.trim().toLowerCase().includes(filterValue);
              }
            }
            return false;
          });
        // to make sorting case insensitive, otherwise by default it doesn't sort words starting with lowercase letters.
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          data[sortHeaderId].toLocaleLowerCase();
        this.loaded = true;
      },
      (error) => (this.error = error.message)
    );
  }

  refresh() {
    this.refresh$.next();
  }

  ngOnDestroy() {
    this.refresh$.complete();
  }

  removeJob(id: string, title: string) {
    this.confirmationDialog
      .confirmDialog({
        title: "batch_jobs.manage_batch_jobs.delete_batch_job_title",
        message: this.translate.instant(
          "batch_jobs.manage_batch_jobs.delete_batch_job_description",
          { title }
        ),
        confirmCaption: "confirmation_popup.delete",
        cancelCaption: "confirmation_popup.cancel",
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.manageBatchJobService.removeJobEntry(id).subscribe(
            () => this.deleteRowDataTable(id),
            (error) => (this.error = error.message)
          );
        }
      });
  }

  private deleteRowDataTable(id: string) {
    const itemIndex = this.dataSource.data.findIndex((obj) => obj.id === id);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;
  }

  routeToCreateBatchJob() {
    this.router.navigate(["createbatchjob"], { relativeTo: this.route });
  }

  applyFilter(value: string) {
    value = value.trim().toLowerCase();
    const sanitizedValue: string = this.sanitizer.sanitize(SecurityContext.HTML, value);
    this.dataSource.filter = sanitizedValue;
  }

  restorePreviousPageSize() {
    const previousPageSize = sessionStorage.getItem("previousPageSize");
    if (this.paginator && previousPageSize != null) {
      this.paginator.pageSize = previousPageSize;
    }
  }

  restorePreviousSort() {
    const previousSort = sessionStorage.getItem("previousSort");
    if (this.sort && previousSort != null) {
      const previousMatSort = JSON.parse(previousSort);
      this.sort.active = previousMatSort.active;
      this.sort.direction = previousMatSort.direction;
    }
  }

  executeNow(id: string, title: string) {
    this.manageBatchJobService.executeBatchJobNow(id).subscribe(() => {
      this.confirmationDialog.confirmDialog({
        title: "batch_jobs.manage_batch_jobs.execute_now_batch_job_title",
        message: this.translate.instant(
          "batch_jobs.manage_batch_jobs.execute_now_batch_job_description",
          { title }
        ),
        confirmCaption: "confirmation_popup.ok",
        cancelCaption: "",
      });
    });
  }
}

interface IJobsDataView extends IJobsData {
  humanReadableSchedule: string;
  formattedDate: string;
  jobStatus: string
}
