import { Component, HostListener, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CronOptions } from "ngx-cron-editor";
import { BreadcrumbService } from "xng-breadcrumb";
import { FileType, JobType, ManageBatchJobsService } from "../manage-batch-jobs.service";
import cronstrue from "cronstrue";
import { TranslateService } from "@ngx-translate/core";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-create-batch-job",
  templateUrl: "./create-batch-job.component.html",
  styleUrls: ["./create-batch-job.component.scss"],
})
export class CreateBatchJobComponent implements OnInit {
  jobTypes = JobType;
  pmlFileName: string;
  pmlCredentialsFileName: string;
  createJobForm: UntypedFormGroup;
  pmlFileResponseGuid: string;
  credFileResponseGuid: string;
  error: string;
  pmlError: string;
  credError: string;
  saveError: string;
  scheduleDescription: string;
  scheduleDescriptionLocal: string;
  isPmlRequired = true;
  isCredRequired = true;
  isMacFile = true;
  isPmlMacFile = true;
  pmlMacroFile: File;
  pmlCredFile: File;

  set schedule(val) {
    if (val === "0/1 * 1/1 * *" || val === "0 0/1 1/1 * *" || val === null || val === undefined) {
      this.scheduleDescription = "Select the desired schedule";
      this.createJobForm.controls["enableSchedule"].disable();
    } else {
      try {
        this.scheduleDescription = `${cronstrue.toString(val)} (GMT+00:00)`;
        this.createJobForm.controls["enableSchedule"].enable();
      } catch (error) {
        this.scheduleDescription = "Invalid Schedule";
        this.createJobForm.controls["enableSchedule"].disable();
      }
    }
  }

  public cronOptions: CronOptions = {
    defaultTime: "00:00:00",

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: true,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: true,
    hideSpecificMonthWeekTab: true,

    use24HourTime: true,
    hideSeconds: true,

    cronFlavor: "standard", // standard or quartz
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private manageBatchJobService: ManageBatchJobsService,
    public breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createJobForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null],
      projectName: [null, [Validators.required]],
      schedule: [null, [Validators.required]],
      jobType: [null, [Validators.required]],
      pmlFileName: [null],
      pmlCredentialsFileName: [null],
      enableSchedule: [false],
    });
    this.breadcrumbService.set(
      "@CreateBatchJob",
      this.translate.instant(`batch_jobs.breadcrumbs.create_page`)
    );
  }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    return !this.createJobForm.dirty;
  }

  onPmlFileSelected(file: File) {
    this.pmlMacroFile = file;
    if (!this.pmlMacroFile) {
      return;
    }

    this.isPmlMacFile = this.pmlMacroFile.name.split(".").pop().toLowerCase() === "pmlmac";
    if (!this.isPmlMacFile) {
      return;
    }

    this.pmlFileName = this.pmlMacroFile.name;
    this.isPmlRequired = false;
  }

  onCredentialFileSelected(file: File) {
    this.pmlCredFile = file;
    if (!this.pmlCredFile) {
      return;
    }

    this.isMacFile = this.pmlCredFile.name.split(".").pop().toLowerCase() === "mac";
    if (!this.isMacFile) {
      return;
    }

    this.pmlCredentialsFileName = this.pmlCredFile.name;
    this.isCredRequired = false;
  }

  onSubmit(formData) {
    const fdMacro = new FormData();
    fdMacro.append("file", this.pmlMacroFile);
    const fdCred = new FormData();
    fdCred.append("file", this.pmlCredFile);

    this.manageBatchJobService.batchJobFileUpload(fdMacro, FileType.Macro).pipe(
      switchMap((data) => {
        this.pmlFileResponseGuid = data.toString();
        formData.pMLFileId = this.pmlFileResponseGuid;
        return this.manageBatchJobService.batchJobFileUpload(fdCred, FileType.Credential);
      }),
      switchMap((credResponse) => {
        this.credFileResponseGuid = credResponse.toString();
        formData.PMLCredentialsFileId = this.credFileResponseGuid;
        formData.pmlCredentialsFileName = this.pmlCredentialsFileName;
        formData.pmlFileName = this.pmlFileName;
        return this.manageBatchJobService.createBatchJob(formData);
      })).subscribe(
        (saveResponse) => {
          this.router.navigate(["/batchjobs/managebatchjobs"]);
          return saveResponse;
        },
        (_error: Error) => {  //eslint-disable-line @typescript-eslint/no-unused-vars
          this.saveError = "Failed to save Batch Job.";
        }
      );
  }

  onCancel() {
    this.router.navigate(["/batchjobs/managebatchjobs"]);
  }
}
